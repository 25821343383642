.avatarGroup {
  display: flex;
}

.avatarItem {
  margin-inline-end: -10px;
  border-width: 1px;
}

.defaultAvatar {
  border: 1px solid var(--color-neutral-lighter);
  border-radius: 50%;
}
